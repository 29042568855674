.main {
  font-size: rem(16);
}

.gdrInfo {
  width: 100%;
  font-weight: 500;
  display: grid;
  font-size: rem(12);
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-area: gdr;
  row-gap: rem(8);
  justify-items: center;
  margin-top: rem(14);

  @media bp(md) {
    margin-top: 0;
  }
}

.gdrLink {
  color: clr(g-40);
}

.gdrButton {
  visibility: hidden;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    visibility: visible;
  }
}
