.header {
  box-sizing: content-box;
  contain: strict;
  font-size: rem(16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  height: rem(39);
  position: sticky;
  background-color: clr(w);
  z-index: 1000001;
  top: rem(-40);
  border-bottom: rem(1) solid clr(cg-90);
  transition: trn(opacity, transform);

  &.preview {
    top: rem(-80);
  }

  @media bp(xl) {
    gap: rem(16);
    background-color: clr(w);
    padding: 0 rem(16) 0 rem(40);
    top: rem(-60);
    height: rem(59);

    &.preview.scrolledUp {
      top: rem(32);
    }
  }
}

.scrolledUp:not(.preview) {
  transform: translateY(rem(40));

  @media bp(xl) {
    transform: translateY(rem(60));
  }
}

.preview {
  top: rem(32);
}

.headerActivated {
  contain: size;
}

.logoLink {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: rem(11);

  @media bp(xl) {
    padding-left: 0;
    flex: 0;
  }
}

.logoIcon {
  height: rem(20);

  @media bp(xl) {
    color: clr(p-70);
  }
}

.dealsButton {
  height: rem(40);
  gap: rem(12);
  align-items: center;
  padding: 0 rem(24);
  font-size: rem(13);
  background-color: clr(g-90);
  border-radius: rem(2);
  order: 3;
  display: none;

  @mixin transition opacity;

  @media bp(xl) {
    display: flex;
  }

  &:hover {
    opacity: 0.8;
  }
}

.dealsIcon {
  width: rem(16);
  height: rem(16);
}

.bellButton {
  @media bp(xl) {
    order: 4;
  }
}

.authBox {
  order: 4;
  height: 100%;
  min-width: rem(45);
  margin-right: rem(12);

  @media bp(xl) {
    order: 5;
    margin-right: 0;
    width: rem(60);
  }
}

.topSearch {
  display: none;

  @media bp(xl) {
    display: inline-block;
  }
}

.searchResults {
  top: rem(40);
  height: calc(100vh - rem(40));

  @media bp(xl) {
    top: rem(60);
    height: auto;
  }
}
